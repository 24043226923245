import { useEffect, useState, useRef } from "react";
import classes from "./HomeFees.module.scss";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import img1 from "../../../assets/img/home/6/1.png";
import img2 from "../../../assets/img/home/6/2.png";
import img3 from "../../../assets/img/home/6/3.png";
import { Trans, useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);
const HomeFees = () => {
  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const mainRef = useRef(null);
  const { t } = useTranslation();
  const slideRef1 = useRef(null);
  const slideRef2 = useRef(null);
  const slideRef3 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(slideRef1.current, {
        y: 0,
        delay: 0.3,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "center center",
          scrub: 1,
        },
      });
      gsap.timeline().to(slideRef2.current, {
        y: 0,
        delay: 0.5,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "center center",
          scrub: 3,
        },
      });
      gsap.timeline().to(slideRef3.current, {
        y: 0,
        delay: 0.7,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "center center",
          scrub: 6,
        },
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={[classes.fees, "mt"].join(" ")} ref={mainRef}>
      <div className={[classes.feesBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.feesTop, ""].join(" ")}>
            <h2 className={[classes.feesTopTitle, "font-35"].join(" ")}>
              <span>{t('home_fees_title')}:</span> {t('home_fees_subtitle')}
            </h2>
            <p className={[classes.feesTopSubtitle, "font-17"].join(" ")}>
              {t('home_fees_text')}
            </p>
          </div>
          <div className={[classes.feesRow, ""].join(" ")}>
            <div
              className={[classes.feesRowColumn, ""].join(" ")}
              ref={slideRef1}
            >
              <div className={[classes.feesRowColumnImg, ""].join(" ")}>
                <img
                  src={img1}
                  alt="FEES: Digital Ventures fees, explained as simply as possible"
                />
              </div>
              <h3 className={[classes.feesRowColumnTitle, "font-17"].join(" ")}>
                {t('home_fees_card_title_01')}
              </h3>
              <p className={[classes.feesRowColumnText, "font-17"].join(" ")}>
                <Trans>
                  {t('home_fees_card_text_01')}
                </Trans>
              </p>
            </div>
            <div
              className={[classes.feesRowColumn, ""].join(" ")}
              ref={slideRef2}
            >
              <div className={[classes.feesRowColumnImg, ""].join(" ")}>
                <img
                  src={img2}
                  alt="FEES: Digital Ventures fees, explained as simply as possible"
                />
              </div>
              <h3 className={[classes.feesRowColumnTitle, "font-17"].join(" ")}>
                {t('home_fees_card_title_02')}
              </h3>
              <p className={[classes.feesRowColumnText, "font-17"].join(" ")}>
                <Trans>
                  {t('home_fees_card_text_02')}
                </Trans>
              </p>
            </div>
            <div
              className={[classes.feesRowColumn, ""].join(" ")}
              ref={slideRef3}
            >
              <div className={[classes.feesRowColumnImg, ""].join(" ")}>
                <img
                  src={img3}
                  alt="FEES: Digital Ventures fees, explained as simply as possible"
                />
              </div>
              <h3 className={[classes.feesRowColumnTitle, "font-17"].join(" ")}>
                {t('home_fees_card_title_03')}
              </h3>
              <p className={[classes.feesRowColumnText, "font-17"].join(" ")}>
                <Trans>
                  {t('home_fees_card_text_03')}
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFees;
