import React, { useState } from "react";
import classes from './Form.module.scss';
import { useTranslation } from "react-i18next";

const Form = () => {
    const { t } = useTranslation();
    const [ checked, setChecked ] = useState(false);
    const [ name, setName ] = useState('');
    const [ surname, setSurname ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ country, setCountry ] = useState('');
    const [ message, setMessage ] = useState('');
    const submit = () => {
        const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (name === '') {
            alert('Empty first name');
            return;
        }
        if (surname === '') {
            alert('Empty last name');
            return;
        }
        if (!expression.test(String(email.toLocaleLowerCase()))) {
            alert('Invalid email');
            return;
        }
        if (phone.length < 1) {
            alert('Invalid phone');
            return;
        }
        if (country === '') {
            alert('Empty country');
            return;
        }
        if (message === '') {
            alert('Empty message');
            return;
        }
        setName('');
        setSurname('');
        setEmail('');
        setPhone('');
        setCountry('');
        setMessage('');
        setChecked(false);
        setTimeout(() => {
            alert('Thank you!');
        }, 500);
    };
    return (
        <div className="mt container">
            <div className={classes.form}>
                <div className={[classes.row, classes.rowGrid].join(' ')}>
                    <div className={classes.col}>
                        <input 
                            type="text"
                            placeholder={t('contact_form_first_name')}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className={classes.col}>
                        <input 
                            type="text"
                            placeholder={t('contact_form_last_name')}
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                        />
                    </div>
                </div>
                <div className={[classes.row, classes.rowGrid].join(' ')}>
                    <div className={classes.col}>
                        <input 
                            type="email"
                            placeholder={t('contact_form_email')}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className={classes.col}>
                        <input 
                            type="tel"
                            placeholder={t('contact_form_phone')}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={classes.row}>
                    <input 
                        type="text"
                        placeholder={t('contact_form_country')}
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                    />
                </div>
                <div className={classes.row}>
                    <textarea 
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder={t('contact_form_message')}
                    />
                </div>
                <div className={classes.row}>
                    <div 
                        className={[classes.check, 'no-select'].join(' ')}
                        onClick={() => setChecked(value => !value)}
                    >
                        <div className={`${classes.checkBox} ${checked && classes.checkBoxActive}`}></div>
                        <p className={classes.checkText}>
                            {t('contact_form_check_privacy')}
                        </p>
                    </div>
                </div>
                <div 
                    className={classes.btn} 
                    onClick={submit}
                >
                    <span>
                        {t('contact_form_submit')}
                    </span>
                </div>
            </div>
        </div>
    ); 
};

export default Form;
