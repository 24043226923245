import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/BonusesPage/Banner/Banner";
import WelcomeBonus from "../../components/BonusesPage/WelcomeBonus/WelcomeBonus";
import LoyaltyRewards from "../../components/BonusesPage/LoyaltyRewards/LoyaltyRewards";
import TermsConditions from "../../components/BonusesPage/TermsConditions/TermsConditions";
import HowItWork from "../../components/BonusesPage/HowItWork/HowItWork";

const BonusesPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Bonuses</title>
        <meta
          name="title"
          content="Digital Ventures Bonuses | Elevate Your Trading Potential with Exciting Rewards"
        />
        <meta
          property="og:title"
          content="Digital Ventures Bonuses | Elevate Your Trading Potential with Exciting Rewards"
        />
        <meta
          property="twitter:title"
          content="Digital Ventures Bonuses | Elevate Your Trading Potential with Exciting Rewards"
        />

        <meta
          name="description"
          content="Explore Digital Ventures's range of bonuses and rewards to enhance your trading experience. Boost your capital and maximize profits with our generous bonuses. Join Digital Ventures today!"
        />
        <meta
          property="og:description"
          content="Explore Digital Ventures's range of bonuses and rewards to enhance your trading experience. Boost your capital and maximize profits with our generous bonuses. Join Digital Ventures today!"
        />
        <meta
          property="twitter:description"
          content="Explore Digital Ventures's range of bonuses and rewards to enhance your trading experience. Boost your capital and maximize profits with our generous bonuses. Join Digital Ventures today!"
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden page-bg">
        <Header />
        <Banner />
        <WelcomeBonus />
        <HowItWork />
        <LoyaltyRewards />
        <TermsConditions />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default BonusesPage;
