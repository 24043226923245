import React from "react";
import classes from "./AboutSpeakToUs.module.scss";
import Button from "../../../components/GlobalComponents/Button/Button";
import img1 from "../../../assets/img/about/4/1.png";
import { useTranslation } from "react-i18next";

const AboutSpeakToUs = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.speak, "mt"].join(" ")}>
      <div className={[classes.speakBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.speakTitle, "font-35"].join(" ")}>
            {t('about_speak_title')}
          </h2>
          <div className={[classes.speakRow, ""].join(" ")}>
            <div className={[classes.speakRowLeft, ""].join(" ")}>
              <p className={[classes.speakRowLeftText, "font-17"].join(" ")}>
                {t('about_speak_text_01')}
              </p>
              <p className={[classes.speakRowLeftText, "font-17"].join(" ")}>
                {t('about_speak_text_02')}
              </p>
              <div className={[classes.speakRowLeftBtn, ""].join(" ")}>
                <Button>
                  {t('about_speak_btn')}
                </Button>
              </div>
            </div>
            <div className={[classes.speakRowRight, ""].join(" ")}>
              <img src={img1} alt="Speak to Us" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSpeakToUs;
