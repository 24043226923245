import React from "react";
import classes from './WelcomeBonus.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";

const WelcomeBonus = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.welcomeBonus}>
                <div className={classes.block}>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-35'].join(' ')}>
                            {t('bonuses_welcome_bonus_title')}
                        </h2>
                        <div className={[classes.text, 'font-17'].join(' ')}>
                            <p>
                                {t('bonuses_welcome_bonus_text')}
                            </p>
                            <p>
                                <b>
                                    {t('bonuses_welcome_bonus_subtitle')}
                                </b>
                            </p>
                        </div>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn_get_bonus')}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.block}>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-35'].join(' ')}>
                            {t('bonuses_reff_bonus_title')}
                        </h2>
                        <div className={[classes.text, 'font-17'].join(' ')}>
                            <p>
                                {t('bonuses_reff_bonus_text_01')}
                            </p>
                            <p>
                                {t('bonuses_reff_bonus_text_02')}
                            </p>
                        </div>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn_get_bonus')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WelcomeBonus;
