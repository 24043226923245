import React from "react";
import classes from "./AboutBanner.module.scss";
import img1 from "../../../assets/img/about/1/1.png";
import img2 from "../../../assets/img/about/1/2.png";
import imgM1 from "../../../assets/img/about/1/m1.png";
import imgM2 from "../../../assets/img/about/1/m2.png";
import imgM3 from "../../../assets/img/about/1/m3.png";
import imgM4 from "../../../assets/img/about/1/m4.png";
import imgMob from "../../../assets/img/about/1/mob.png";
import { Trans, useTranslation } from "react-i18next";

const AboutBanner = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.about, ""].join(" ")}>
      <div className={[classes.aboutBody, ""].join(" ")}>
        <div className={[classes.aboutImg, ""].join(" ")}>
          <img src={img1} alt="Maximize your decision-making" />
        </div>

        <div className="container">
          <div className={[classes.aboutRow, ""].join(" ")}>
            <div className={[classes.aboutRowLeft, ""].join(" ")}>
              <h2 className={[classes.aboutRowLeftTitle, "font-35"].join(" ")}>
                <Trans>
                  {t('about_banner_title')}
                </Trans>
              </h2>
              <p className={[classes.aboutRowLeftText, "font-17"].join(" ")}>
                {t('about_banner_text_01')}
              </p>
              <p className={[classes.aboutRowLeftText, "font-17"].join(" ")}>
                {t('about_banner_text_02')}
              </p>
            </div>
            <div className={[classes.aboutRowRight, ""].join(" ")}>
              <img src={img2} alt="Maximize your decision-making" />
              <div className={[classes.aboutRowRightImg1, ""].join(" ")}>
                <img src={imgM1} alt="Maximize your decision-making" />
              </div>
              <div className={[classes.aboutRowRightImg2, ""].join(" ")}>
                <img src={imgM2} alt="Maximize your decision-making" />
              </div>
              <div className={[classes.aboutRowRightImg3, ""].join(" ")}>
                <img src={imgM3} alt="Maximize your decision-making" />
              </div>
              <div className={[classes.aboutRowRightImg4, ""].join(" ")}>
                <img src={imgM4} alt="Maximize your decision-making" />
              </div>
            </div>
          </div>
          <div className={[classes.mob, ""].join(" ")}>
            <img src={imgMob} alt="Maximize your decision-making" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBanner;
