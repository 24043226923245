import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/CommoditiesPage/Banner/Banner";
import EnergyComm from "../../components/CommoditiesPage/EnergyComm/EnergyComm";
import Solutions from "../../components/CommoditiesPage/Solutions/Solutions";
import NaturalGas from "../../components/CommoditiesPage/NaturalGas/NaturalGas";
import PowerTrading from "../../components/CommoditiesPage/PowerTrading/PowerTrading";
import BaseOil from "../../components/CommoditiesPage/BaseOil/BaseOil";
import SolutionsBottom from "../../components/CommoditiesPage/SolutionsBottom/SolutionsBottom";
import HomeOurPartners from "../../components/MainComponents/HomeOurPartners/HomeOurPartners";

const CommoditiesPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Commodities</title>
        <meta name="title" content="Commodities Trading on Digital Ventures | Diversify Your Portfolio with Global Opportunities" />
        <meta property="og:title" content="Commodities Trading on Digital Ventures | Diversify Your Portfolio with Global Opportunities" />
        <meta property="twitter:title" content="Commodities Trading on Digital Ventures | Diversify Your Portfolio with Global Opportunities" />

        <meta name="description" content="Trade a variety of commodities on Digital Ventures. From agricultural products to energy resources and metals, seize global opportunities and diversify your investment portfolio." />
        <meta property="og:description" content="Trade a variety of commodities on Digital Ventures. From agricultural products to energy resources and metals, seize global opportunities and diversify your investment portfolio." />
        <meta property="twitter:description" content="Trade a variety of commodities on Digital Ventures. From agricultural products to energy resources and metals, seize global opportunities and diversify your investment portfolio." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <EnergyComm />
        <Solutions />
        <NaturalGas />
        <BaseOil />
        <PowerTrading />
        <SolutionsBottom />
        <HomeOurPartners />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default CommoditiesPage;
