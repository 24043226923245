import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import AccountsBanner from "../../components/AccountsComponents/AccountsBanner/AccountsBanner";
import AccountsWhat from "../../components/AccountsComponents/AccountsWhat/AccountsWhat";
import AccountsWhyChoose from "../../components/AccountsComponents/AccountsWhyChoose/AccountsWhyChoose";
import HomeOurPartners from "../../components/MainComponents/HomeOurPartners/HomeOurPartners";
import Benefits from "../../components/AccountsComponents/Benefits/Benefits";
const AccountsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Trading Account</title>
        <meta
          name="title"
          content="Digital Ventures Trading Account | Experience Unlimited Investment Options"
        />
        <meta
          property="og:title"
          content="Digital Ventures Trading Account | Experience Unlimited Investment Options"
        />
        <meta
          property="twitter:title"
          content="Digital Ventures Trading Account | Experience Unlimited Investment Options"
        />

        <meta
          name="description"
          content="Open a Digital Ventures Trading Account and gain access to a wide range of investment options. Trade UK and international shares, funds, and more with a low monthly fee."
        />
        <meta
          property="og:description"
          content="Open a Digital Ventures Trading Account and gain access to a wide range of investment options. Trade UK and international shares, funds, and more with a low monthly fee."
        />
        <meta
          property="twitter:description"
          content="Open a Digital Ventures Trading Account and gain access to a wide range of investment options. Trade UK and international shares, funds, and more with a low monthly fee."
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden page-bg">
        <Header />
        <AccountsBanner />
        <AccountsWhat />
        <AccountsWhyChoose />
        <Benefits />
        <HomeOurPartners />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AccountsPage;
