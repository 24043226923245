import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/CommoditiesPage/banner.png';

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <h1 className={[classes.title, 'font-35'].join(' ')}>
                        {t('comm_banner_title')}
                    </h1>
                    <div className={[classes.text, 'font-17'].join(' ')}>
                        <p>
                            {t('comm_banner_text_01')}
                        </p>
                        <p>
                            {t('comm_banner_text_02')}
                        </p>
                        <p>
                            {t('comm_banner_text_03')}
                        </p>
                    </div>
                    <div className={classes.btn}>
                        <Button>
                            {t('btn_start_trading')}
                        </Button>
                    </div>
                </div>
                <img className={classes.image} src={image} alt=''/>
            </div>
        </section>
    );
};

export default Banner;