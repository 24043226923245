import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./components/GlobalComponents/Router/AppRouter";
import './App.scss';
import { images } from "./utils/imagesToLoad";
import $ from 'jquery';
import { HelmetProvider } from "react-helmet-async";
import Loader from "./components/GlobalComponents/Loader/Loader";
import {useVisitorData} from '@fingerprintjs/fingerprintjs-pro-react'
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { load } from '@fingerprintjs/botd';
import ContactManager from "./components/GlobalComponents/ContactManager/ContactManager";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };
  useEffect(() => {
    if (isLoading) {
      $('body').addClass("ovf-hidden");
    }
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => console.log("Failed to load images", err));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!isLoading) {
      $('body').removeClass("ovf-hidden");
    }
  }, [isLoading]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 
  // eslint-disable-next-line
  const data = useVisitorData(
    {extendedResult: true},
    {immediate: true}
  )
  // eslint-disable-next-line
  const [ visId, setVisId ] = useState(null);
  const botdPromise = load()
  const [ isBot, setIsBot ] = useState(null);
  
  useEffect(() => {
    (async () => {
      const agent = await FingerprintJS.load();
      const fngprint = await agent.get();
      setVisId(fngprint);
      
      botdPromise
        .then((botd) => botd.detect())
        .then((result) => {
          setIsBot(result.bot);
        })
        .catch((error) => console.error(error))
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Loader isLoading={isLoading} />
      {/* {isBot === false && */}
        <HelmetProvider>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
        </HelmetProvider>
      {/* } */}
      {isBot === true &&
        <ContactManager />
      }
    </>
  );
}

export default App;
