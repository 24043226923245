import React, { useEffect, useRef } from "react";
import classes from './HowItWork.module.scss';
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { CustomEase } from 'gsap/src/all';
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);

const HowItWork = () => {
    const { t } = useTranslation();
    const bodyRef = useRef(null);
    const cardsRef = useRef(null);
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            title: 'bonuses_how_it_work_card_01',
            num: '10 %',
        },
        {
            ref: cardRef_02,
            title: 'bonuses_how_it_work_card_02',
            num: '25 %',
        },
        {
            ref: cardRef_03,
            title: 'bonuses_how_it_work_card_03',
            num: '40 %',
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                y: 500,
            }, {
                y: 0,
                ease: 'none',
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=1000px",
                    scrub: true,
                    invalidateOnRefresh: true,
                }
            });
            gsap.timeline().fromTo(cardRef_02.current, {
                y: 1000,
            }, {
                y: 0,
                ease: 'none',
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=1500px",
                    scrub: true,
                    invalidateOnRefresh: true,
                }
            });
            gsap.timeline().fromTo(cardRef_03.current, {
                y: 900,
            }, {
                y: 0,
                ease: CustomEase.create("custom", "M0,0,C0,0,0.3,0.328,0.378,0.442,0.478,0.542,0.486,0.548,0.57,0.636,0.644,0.714,0.622,0.691,0.672,0.744,0.716,0.79,0.7,0.766,0.736,0.806,0.766,0.84,0.762,0.838,0.788,0.864,0.827,0.903,0.823,0.894,0.862,0.93,0.908,0.972,0.882,0.948,0.926,0.982,0.955,1.004,1,1,1,1"),
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=1000px",
                    scrub: true,
                    invalidateOnRefresh: true,
                    pin: bodyRef.current,
                }
            });
        }
    }, [])
    return (
        <section id="bodyRefPin" ref={bodyRef} className={classes.solutionsWrap}>
            <div className="container">
                <div className={classes.solutions}>
                    <div className={classes.bg}></div>
                    <h2 className={classes.title}>
                        {t('bonuses_how_it_work_title')}
                    </h2>
                    <p className={[classes.text, 'font-17'].join(' ')}>
                        <p>
                            {t('bonuses_how_it_work_text_01')}
                        </p>
                        <p>
                            {t('bonuses_how_it_work_text_02')}
                        </p>
                        <p className={classes.desc}>
                            {t('bonuses_how_it_work_text_03')}
                        </p>
                    </p>
                    <div ref={cardsRef} className={classes.cards}>
                        {cards.map((card, index) =>
                            <div ref={card.ref} key={index} className={[classes.card, 'cardTextTest'].join(' ')}>
                                <div className={classes.cardContent}>
                                    <h4 className={classes.cardTitle}>
                                        {t(card.title)}
                                    </h4>
                                    <p className={[classes.cardText_01, 'font-17'].join(' ')}>
                                        {t('bonuses_how_it_work_EARN')}
                                    </p>
                                    <h5 className={classes.cardNum}>
                                        {card.num}
                                    </h5>
                                    <p className={[classes.cardText_02, 'font-17'].join(' ')}>
                                        {t('bonuses_how_it_work_PER_FRIEND')}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowItWork;
