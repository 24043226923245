import { useEffect, useState, useRef } from "react";
import classes from "./HomeSolutions.module.scss";
import img1 from "../../../assets/img/home/3/1.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import arrow from "../../../assets/img/home/3/right.png";
import "swiper/css/pagination";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { Trans, useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const HomeSolutions = () => {
  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const { t } = useTranslation();
  const mainRef = useRef(null);
  const slideRef1 = useRef(null);
  const slideRef2 = useRef(null);
  const slideRef3 = useRef(null);
  const slideRef4 = useRef(null);

  const cards = [
    {
      id: 1,
      title: "home_solutions_card_01_title",
      desc: "home_solutions_card_01_text",
    },
    {
      id: 2,
      title: "home_solutions_card_02_title",
      desc: "home_solutions_card_02_text",
    },
    {
      id: 3,
      title: "home_solutions_card_03_title",
      desc: "home_solutions_card_03_text",
    },
    {
      id: 4,
      title: "home_solutions_card_04_title",
      desc: "home_solutions_card_04_text",
    },
  ];

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(slideRef1.current, {
        y: 0,
        delay: 0.3,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "center center",
          scrub: 1,
        },
      });
      gsap.timeline().to(slideRef2.current, {
        y: 0,
        delay: 0.5,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "center center",
          scrub: 3,
        },
      });
      gsap.timeline().to(slideRef3.current, {
        y: 0,
        delay: 0.7,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "center center",
          scrub: 6,
        },
      });
      gsap.timeline().to(slideRef4.current, {
        y: 0,
        delay: 0.9,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "center center",
          scrub: 9,
        },
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className={[classes.solutions, ""].join(" ")} ref={mainRef}>
      <div className={[classes.solutionsBody, ""].join(" ")}>
        <div className={[classes.solutionsImg, ""].join(" ")}>
          <img src={img1} alt="Solutions" />
        </div>

        <div className="container">
          <div className={[classes.solutionsRow, ""].join(" ")}>
            <div className={[classes.solutionsRowLeft, ""].join(" ")}>
              <h2 className={[classes.solutionsRowLeftTitle, ""].join(" ")}>
                <Trans>
                  {t('home_solutions_title')}
                </Trans>
              </h2>
              <p
                className={[classes.solutionsRowLeftSubtitle, "font-17"].join(
                  " "
                )}
              >
                {t('home_solutions_subtitle')}
              </p>
              <p
                className={[classes.solutionsRowLeftText, "font-17"].join(" ")}
              >
                {t('home_solutions_text_01')}
              </p>
              <p
                className={[classes.solutionsRowLeftText, "font-17"].join(" ")}
              >
                {t('home_solutions_text_02')}
              </p>
            </div>
            <div className={[classes.solutionsRowRight, ""].join(" ")}>
              <div className={[classes.solutionsRowRightColumns, ""].join(" ")}>
                <div
                  className={[classes.solutionsRowRightColumnsColumn, ""].join(
                    " "
                  )}
                  ref={slideRef1}
                >
                  <h3
                    className={[
                      classes.solutionsRowRightColumnsColumnTitle,
                      "font-25",
                    ].join(" ")}
                  >
                    {t('home_solutions_card_01_title')}
                  </h3>
                  <p
                    className={[
                      classes.solutionsRowRightColumnsColumnText,
                      "font-17",
                    ].join(" ")}
                  >
                    {t('home_solutions_card_01_text')}
                  </p>
                </div>
                <div
                  className={[classes.solutionsRowRightColumnsColumn, ""].join(
                    " "
                  )}
                  ref={slideRef2}
                >
                  <h3
                    className={[
                      classes.solutionsRowRightColumnsColumnTitle,
                      "font-25",
                    ].join(" ")}
                  >
                    {t('home_solutions_card_02_title')}
                  </h3>
                  <p
                    className={[
                      classes.solutionsRowRightColumnsColumnText,
                      "font-17",
                    ].join(" ")}
                  >
                    {t('home_solutions_card_02_text')}
                  </p>
                </div>
                <div
                  className={[classes.solutionsRowRightColumnsColumn, ""].join(
                    " "
                  )}
                  ref={slideRef3}
                >
                  <h3
                    className={[
                      classes.solutionsRowRightColumnsColumnTitle,
                      "font-25",
                    ].join(" ")}
                  >
                    {t('home_solutions_card_03_title')}
                  </h3>
                  <p
                    className={[
                      classes.solutionsRowRightColumnsColumnText,
                      "font-17",
                    ].join(" ")}
                  >
                    {t('home_solutions_card_03_text')}
                  </p>
                </div>
                <div
                  className={[classes.solutionsRowRightColumnsColumn, ""].join(
                    " "
                  )}
                  ref={slideRef4}
                >
                  <h3
                    className={[
                      classes.solutionsRowRightColumnsColumnTitle,
                      "font-25",
                    ].join(" ")}
                  >
                    {t('home_solutions_card_04_title')}
                  </h3>
                  <p
                    className={[
                      classes.solutionsRowRightColumnsColumnText,
                      "font-17",
                    ].join(" ")}
                  >
                    {t('home_solutions_card_04_text')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={[classes.solutionsMob, ""].join(" ")}>
            <div className="container1">
              <div className={[classes.chooseAccountRowRight, ""].join(" ")}>
                <div className={classes.pagination}>
                  <img
                    ref={prevRef}
                    className={classes.pagPrev}
                    src={arrow}
                    alt=""
                  />
                  <img
                    ref={nextRef}
                    className={classes.pagNext}
                    src={arrow}
                    alt=""
                  />
                </div>
              </div>

              <div className={classes.swiperWr}>
                <Swiper
                  modules={[Pagination, Navigation]}
                  className="chooseAccountTypeSwiper"
                  spaceBetween={70}
                  slidesPerView={"auto"}
                  pagination={true}
                  navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                  }}
                  breakpoints={{
                    100: {
                      spaceBetween: 20,
                    },
                    992: {
                      spaceBetween: 30,
                    },
                    1350: {
                      spaceBetween: 50,
                    },
                    1500: {
                      spaceBetween: 70,
                    },
                  }}
                  onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = prevRef.current;
                    swiper.params.navigation.nextEl = nextRef.current;
                  }}
                >
                  {cards.map((card) => (
                    <SwiperSlide
                      key={card.id}
                      className="chooseAccountTypeSwiperSlide"
                    >
                      <div
                        className={[
                          classes.solutionsRowRightColumnsColumn,
                          "",
                        ].join(" ")}
                      >
                        <h3
                          className={[
                            classes.solutionsRowRightColumnsColumnTitle,
                            "font-25",
                          ].join(" ")}
                        >
                          {t(card.title)}
                        </h3>
                        <p
                          className={[
                            classes.solutionsRowRightColumnsColumnText,
                            "font-17",
                          ].join(" ")}
                        >
                          {t(card.desc)}
                        </p>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSolutions;
