import React from "react";
import classes from "./HomeGraphic.module.scss";
import img1 from "../../../assets/img/home/2/1.png";
import imgM1 from "../../../assets/img/home/2/m1.png";
import imgM2 from "../../../assets/img/home/2/m2.png";
import imgM3 from "../../../assets/img/home/2/m3.png";
import imgM4 from "../../../assets/img/home/2/m4.png";
import imgM5 from "../../../assets/img/home/2/m5.png";
import imgMob from "../../../assets/img/home/2/mob.png";
import { useTranslation } from "react-i18next";

const HomeGraphic = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.graphic, ""].join(" ")}>
      <div className={[classes.graphicBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.graphicRow, ""].join(" ")}>
            <div className={[classes.graphicRowLeft, ""].join(" ")}>
              <div className={[classes.graphicRowLeftImg, ""].join(" ")}>
                <img
                  src={img1}
                  alt="Digital Ventures - Empowering Independent Commodity Trading"
                />
              </div>
              <div className={[classes.graphicRowLeftImgM1, ""].join(" ")}>
                <img
                  src={imgM1}
                  alt="Digital Ventures - Empowering Independent Commodity Trading"
                />
              </div>
              <div className={[classes.graphicRowLeftImgM2, ""].join(" ")}>
                <img
                  src={imgM2}
                  alt="Digital Ventures - Empowering Independent Commodity Trading"
                />
              </div>
              <div className={[classes.graphicRowLeftImgM3, ""].join(" ")}>
                <img
                  src={imgM3}
                  alt="Digital Ventures - Empowering Independent Commodity Trading"
                />
              </div>

              <div className={[classes.graphicRowLeftImgM4, ""].join(" ")}>
                <img
                  src={imgM4}
                  alt="Digital Ventures - Empowering Independent Commodity Trading"
                />
              </div>
              <div className={[classes.graphicRowLeftImgM5, ""].join(" ")}>
                <img
                  src={imgM5}
                  alt="Digital Ventures - Empowering Independent Commodity Trading"
                />
              </div>
            </div>
            <div className={[classes.graphicRowRight, ""].join(" ")}>
              <p className={[classes.graphicRowRightText, "font-17"].join(" ")}>
                {t('home_banner_text_02')}
              </p>
              <p className={[classes.graphicRowRightText, "font-17"].join(" ")}>
                {t('home_banner_text_03')}
              </p>
              <p className={[classes.graphicRowRightText, "font-17"].join(" ")}>
                {t('home_banner_text_04')}
              </p>
            </div>
          </div>
          <div className={[classes.mob, ""].join(" ")}>
            <img
              src={imgMob}
              alt="Digital Ventures - Empowering Independent Commodity Trading"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeGraphic;
