import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import HomeBanner from "../../components/MainComponents/HomeBanner/HomeBanner";
import HomeGraphic from "../../components/MainComponents/HomeGraphic/HomeGraphic";
import HomeSolutions from "../../components/MainComponents/HomeSolutions/HomeSolutions";
import HomeTrade from "../../components/MainComponents/HomeTrade/HomeTrade";
import HomeNotebook from "../../components/MainComponents/HomeNotebook/HomeNotebook";
import HomeFees from "../../components/MainComponents/HomeFees/HomeFees";
import HomeFaq from "../../components/MainComponents/HomeFaq/HomeFaq";
import Slider from "../../components/MainComponents/Slider/Slider";
import HomeOurPartners from "../../components/MainComponents/HomeOurPartners/HomeOurPartners";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <title>Digital Ventures</title>
        <meta
          name="title"
          content="Digital Ventures Independent Commodity Trading | Your Gateway to Profitable Trading"
        />
        <meta
          property="og:title"
          content="Digital Ventures Independent Commodity Trading | Your Gateway to Profitable Trading"
        />
        <meta
          property="twitter:title"
          content="Digital Ventures Independent Commodity Trading | Your Gateway to Profitable Trading"
        />

        <meta
          name="description"
          content="Discover Digital Ventures, the leading independent commodity trading platform. Access real-time market data, trade commodities, and maximize your profits. Join now!"
        />
        <meta
          property="og:description"
          content="Discover Digital Ventures, the leading independent commodity trading platform. Access real-time market data, trade commodities, and maximize your profits. Join now!"
        />
        <meta
          property="twitter:description"
          content="Discover Digital Ventures, the leading independent commodity trading platform. Access real-time market data, trade commodities, and maximize your profits. Join now!"
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden page-bg">
        <Header />
        <HomeBanner />
        <HomeGraphic />
        <HomeSolutions />
        <HomeTrade />
        <HomeNotebook />
        <HomeFees />
        <HomeFaq />
        <Slider />
        <HomeOurPartners />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
