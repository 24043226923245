import React from "react";
import classes from '../LoyaltyRewards/LoyaltyRewards.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/BonusesPage/terms.png';

const TermsConditions = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={[classes.loyaltyRewards, classes.reverse].join(' ')}>
                <div className={classes.block}>
                    <img className={classes.image_02} src={image} alt=''/>
                </div>
                <div className={classes.block}>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-35'].join(' ')}>
                            {t('bonuses_terms_title')}
                        </h2>
                        <div className={[classes.text, 'font-17'].join(' ')}>
                            <p>
                                {t('bonuses_terms_text_01')}
                            </p>
                            <p>
                                {t('bonuses_terms_text_02')}
                            </p>
                        </div>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn_get_bonus')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TermsConditions;
