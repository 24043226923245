import React from "react";
import img1 from "../../../assets/img/HomePage/Slider/1.png";
import img2 from "../../../assets/img/HomePage/Slider/2.png";
import img3 from "../../../assets/img/HomePage/Slider/3.png";
import classes from "./Slider.module.scss";
import imgLeft from "../../../assets/img/HomePage/Slider/left.svg";
import imgRight from "../../../assets/img/HomePage/Slider/right.svg";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import "./styles.scss";
import { Slide } from "./testimonial.js";
import { useTranslation } from "react-i18next";

const data = [
  {
    image: img1,
    text: "home_what_our_slide_text_01",
    name: "Sam",
    star: 90,
    colorStars: classes.tradersSliderSlideItemTopStarsProgress5,
  },
  {
    image: img2,
    text: "home_what_our_slide_text_02",
    name: "Marina",
    star: 95,
    colorStars: classes.tradersSliderSlideItemTopStarsProgress5,
  },
  {
    image: img3,
    text: "home_what_our_slide_text_03",
    name: "Calvin",
    star: 97,
    colorStars: classes.tradersSliderSlideItemTopStarsProgress5,
  },
];

const Slider = () => {
  const { t } = useTranslation();
  const ref = React.useRef(StackedCarousel);
  return (
    <div className={[classes.slider, "mt"].join(" ")}>
      <h3 className={[classes.title, "font-35"].join(" ")}>
        {t('home_what_our_title')}
      </h3>
      <div className="card card-carrier no-select">
        <div style={{ position: "relative" }}>
          <ResponsiveContainer
            carouselRef={ref}
            render={(width, carouselRef) => {
              let currentVisibleSlide = 3;
              if (width <= 1280) currentVisibleSlide = 3;
              if (width <= 720) currentVisibleSlide = 1;
              return (
                <StackedCarousel
                  ref={carouselRef}
                  slideComponent={Slide}
                  slideWidth={width > 1650 ? 1074 : width > 1220 ? 750 : 500}
                  carouselWidth={width}
                  data={data}
                  maxVisibleSlide={3}
                  customScales={[
                    1,
                    width > 1220 ? 0.55 : 0.65,
                    width > 1900
                      ? -0.19
                      : width > 1850
                      ? -0.21
                      : width > 1800
                      ? -0.23
                      : width > 1750
                      ? -0.25
                      : width > 1700
                      ? -0.27
                      : width > 1650
                      ? -0.29
                      : width > 1600
                      ? -0.02
                      : width > 1550
                      ? -0.05
                      : width > 1500
                      ? -0.07
                      : width > 1450
                      ? -0.1
                      : width > 1400
                      ? -0.13
                      : width > 1350
                      ? -0.17
                      : width > 1300
                      ? -0.2
                      : width > 1250
                      ? -0.23
                      : width > 1220
                      ? -0.26
                      : width > 1150
                      ? 0.7
                      : width > 1100
                      ? 0.5
                      : width > 1050
                      ? 0.4
                      : width > 1000
                      ? 0.4
                      : width > 950
                      ? 0.4
                      : width > 900
                      ? 0.2
                      : 0.2,
                  ]}
                  transitionTime={450}
                  currentVisibleSlide={currentVisibleSlide}
                />
              );
            }}
          />
          <div className={classes.arrows}>
            <div
              className={classes.arrowsLeft}
              onClick={() => ref.current?.goBack()}
            >
              <img src={imgLeft} alt="" />
            </div>
            <div
              className={classes.arrowsRight}
              onClick={() => ref.current?.goNext()}
            >
              <img src={imgRight} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
