import React from "react";
import classes from "./Benefits.module.scss";
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/trading/benefits.png';

const Benefits = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.whyChoose, "mt mb"].join(" ")}>
      <div className={classes.whyChooseBody}>
        <div className="container">
          <div className={classes.whyChooseRow}>
            <div className={classes.whyChooseRowLeft}>
              <h2 className={[classes.whyChooseRowLeftTitle, "font-35"].join(" ")}>
                {t('accounts_benefits_title')}
              </h2>
              <ul className={classes.whyChooseRowLeftList}>
                <li className={[classes.whyChooseRowLeftListItem, "font-17"].join(" ")}>
                  <Trans>
                    {t('accounts_benefits_text_01')}
                  </Trans>
                </li>
                <li className={[classes.whyChooseRowLeftListItem, "font-17"].join(" ")}>
                  <Trans>
                    {t('accounts_benefits_text_02')}
                  </Trans>
                </li>
                <li className={[classes.whyChooseRowLeftListItem, "font-17"].join(" ")}>
                  <Trans>
                    {t('accounts_benefits_text_03')}
                  </Trans>
                </li>
              </ul>
            </div>
            <img className={classes.image} src={image} alt=''/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
