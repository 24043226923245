import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import classes from "./HomeBanner.module.scss";
import Button from "../../GlobalComponents/Button/Button";

import img1 from "../../../assets/img/home/1/1.png";
import imgM1 from "../../../assets/img/home/1/m1.png";
import imgM2 from "../../../assets/img/home/1/m2.png";
import imgM3 from "../../../assets/img/home/1/m3.png";
import imgM4 from "../../../assets/img/home/1/m4.png";
import imgGif from "../../../assets/img/home/1/1.gif";
import imgMob from "../../../assets/img/home/1/mob.png";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);
const HomeBanner = () => {
  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const mainRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(mainRef.current, {
        delay: 0.3,
        duration: 1,
        scale: 1,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={[classes.homeBanner, ""].join(" ")}>
      <div className={[classes.homeBannerBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.homeBannerRow, ""].join(" ")}>
            <div className={[classes.homeBannerRowLeft, ""].join(" ")}>
              <h1
                className={[classes.homeBannerRowLeftTitle, "font-35"].join(
                  " "
                )}
              >
                {t('home_banner_title')}
              </h1>
              <p
                className={[classes.homeBannerRowLeftText, "font-17"].join(" ")}
              >
                {t('home_banner_text_01')}
              </p>
              <div className={[classes.homeBannerRowLeftBtn, ""].join(" ")}>
                <Button>
                  {t('btn_start_trading')}
                </Button>
              </div>
            </div>
            <div className={[classes.homeBannerRowRight, ""].join(" ")}>
              <div className={[classes.homeBannerRowRightImgs, ""].join(" ")}>
                <div className={[classes.homeBannerImg1, ""].join(" ")}>
                  <img
                    ref={mainRef}
                    src={img1}
                    alt="Digital Ventures - Empowering Independent Commodity Trading"
                  />
                </div>
                <div className={[classes.homeBannerImgM1, ""].join(" ")}>
                  <img
                    src={imgM1}
                    alt="Digital Ventures - Empowering Independent Commodity Trading"
                  />
                </div>
                <div className={[classes.homeBannerImgM2, ""].join(" ")}>
                  <img
                    src={imgM2}
                    alt="Digital Ventures - Empowering Independent Commodity Trading"
                  />
                </div>
                <div className={[classes.homeBannerImgM3, ""].join(" ")}>
                  <img
                    src={imgM3}
                    alt="Digital Ventures - Empowering Independent Commodity Trading"
                  />
                </div>
                <div className={[classes.homeBannerImgM4, ""].join(" ")}>
                  <img
                    src={imgM4}
                    alt="Digital Ventures - Empowering Independent Commodity Trading"
                  />
                </div>
                <div className={[classes.homeBannerGif, ""].join(" ")}>
                  <img
                    src={imgGif}
                    alt="Digital Ventures - Empowering Independent Commodity Trading"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={[classes.mob, ""].join(" ")}>
            <img
              src={imgMob}
              alt="Digital Ventures - Empowering Independent Commodity Trading"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
