import React from "react";
import classes from "./AccountsBanner.module.scss";
import img1 from "../../../assets/img/trading/1/1.png";
import imgMob from "../../../assets/img/trading/1/mob.png";
import imgMobBg from "../../../assets/img/trading/1/mobBg.png";
import { useTranslation } from "react-i18next";

const AccountsBanner = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.accounts, "mb"].join(" ")}>
      <div className={[classes.accountsMobBg, ""].join(" ")}>
        <img src={imgMobBg} alt="" />
      </div>
      <div className={[classes.accountsBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.accountsWrap, ""].join(" ")}>
            <h2 className={[classes.accountsTitle, "font-35"].join(" ")}>
              {t('accounts_banner_title')}
            </h2>
            <p className={[classes.accountsSubtitle, "font-17"].join(" ")}>
              {t('accounts_banner_subtitle')}
            </p>
            <p className={[classes.accountsText, ""].join(" ")}>
              {t('accounts_banner_text')}
            </p>
          </div>
          <div className={[classes.mob, ""].join(" ")}>
            <img src={imgMob} alt="" />
          </div>
        </div>
        <div className={[classes.accountsImg, ""].join(" ")}>
          <img src={img1} alt="Trading Account" />
        </div>
      </div>
    </div>
  );
};

export default AccountsBanner;
