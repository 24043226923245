import React, { useState } from "react";
import classes from "./Solutions.module.scss";
import imgLeft from "../../../assets/img/about/3/left.png";
import imgRight from "../../../assets/img/about/3/right.png";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const AboutOurValues = () => {
  const { t } = useTranslation();
  const [count, setCount] = useState(1);

  const [contents, setContents] = useState([
    {
      id: 1,
      title: "comm_solutions_card_title_01",
      text: "comm_solutions_card_text_01",
      active: true,
    },
    {
      id: 2,
      title: "comm_solutions_card_title_02",
      text: "comm_solutions_card_text_02",
      active: false,
    },
    {
      id: 3,
      title: "comm_solutions_card_title_03",
      text: "comm_solutions_card_text_03",
      active: false,
    },
    {
      id: 4,
      title: "comm_solutions_card_title_04",
      text: "comm_solutions_card_text_04",
      active: false,
    },
  ]);

  const swipeRight = () => {
    if (count >= contents.length) {
      setCount(1);
    } else {
      setCount(count + 1);
    }
  };
  const swipeLeft = () => {
    if (count <= 1) {
      setCount(contents.length);
    } else {
      setCount(count - 1);
    }
  };

  useEffect(() => {
    setContents(
      contents.map((item) => {
        if (item.id === count) {
          return { ...item, active: true };
        } else {
          return { ...item, active: false };
        }
      })
    );
    // eslint-disable-next-line
  }, [count]);

  return (
    <div className={[classes.values, "mt"].join(" ")}>
      <div className={classes.valuesBody}>
        <div className="container">
          <div className={classes.valuesRow}>
            <div className={classes.valuesRowLeft}>
              <h2 className={[classes.valuesRowLeftTitle, "font-35"].join(" ")}>
                {t('comm_solutions_title')}
              </h2>
              <p className={[classes.valuesRowLeftText, "font-17"].join(" ")}>
                {t('comm_solutions_text')}
              </p>
            </div>
            <div className={classes.valuesRowRight}>
              <div className={classes.valuesRowRightTop}>
                <div className={classes.valuesRowRightTopLeft}>
                  <h2 className={[classes.valuesRowLeftTitle, classes.opacity, "font-35"].join(" ")}>
                    {t('about_our_values_title')}
                  </h2>
                  <div className={classes.valuesRowRightTopArrows}>
                    <div
                      className={classes.valuesRowRightTopArrowsImg}
                      onClick={() => {swipeLeft();}}
                    >
                      <img src={imgLeft} alt="Our Values" />
                    </div>
                    <div
                      className={classes.valuesRowRightTopArrowsImg}
                      onClick={() => {swipeRight();}}
                    >
                      <img src={imgRight} alt="Our Values" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.valuesRowRightContent}>
                {// eslint-disable-next-line
                contents.map((i) => {
                  if (i.active) {
                    return (
                        <div
                            key={i.id}
                            className={classes.valuesRowRightContentItem}
                        >
                            <h3 className={[classes.valuesRowRightContentItemTitle, "font-17"].join(" ")}>
                                {t(i.title)}
                            </h3>
                            <p className={[classes.valuesRowRightContentItemText, "font-17"].join(" ")}>
                                {t(i.text)}
                            </p>
                        </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOurValues;
