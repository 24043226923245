import React from "react";
import classes from '../NaturalGas/NaturalGas.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/CommoditiesPage/power_trading_01.png';
import image_02 from '../../../assets/img/CommoditiesPage/power_trading_02.png';
import Button from "../../GlobalComponents/Button/Button";

const PowerTrading = () => {
    const { t } = useTranslation();
    return (
        <>
            <section className="mt container">
                <div className={classes.container}>
                    <div className={classes.col}>
                        <div className={classes.content}>
                            <h2 className={[classes.title, 'font-35'].join(' ')}>
                                {t('comm_power_trading_title')}
                            </h2>
                            <div className={[classes.text, 'font-17'].join(' ')}>
                                <p>
                                    {t('comm_power_trading_text_01')}
                                </p>
                                <p>
                                    {t('comm_power_trading_text_02')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.col, classes.md4dnone].join(' ')}>
                        <img className={classes.image_03} src={image_01} alt=''/>
                    </div>
                </div>
            </section>
            <section className={["mt container", classes.mt4margin].join(' ')}>
                <div className={[classes.container, classes.containerReverse].join(' ')}>
                    <div className={classes.col}>
                        <img className={classes.image_04} src={image_02} alt=''/>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.content}>
                            <div className={[classes.text, 'font-17'].join(' ')}>
                                <p>
                                    {t('comm_power_trading_text_03')}
                                </p>
                                <p>
                                    {t('comm_power_trading_text_04')}
                                </p>
                            </div>
                            <div className={classes.btn}>
                                <Button>
                                    {t('btn_start_trading')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PowerTrading;
