import React from "react";
import classes from "./AboutStory.module.scss";
import img1 from "../../../assets/img/about/2/1.png";
import imgMob from "../../../assets/img/about/2/mob.png";
import palmaImg from "../../../assets/img/about/2/palma.png";
import { Trans, useTranslation } from "react-i18next";

const AboutStory = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.about, "mt"].join(" ")}>
      <div className={[classes.aboutBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.solutionsRow, ""].join(" ")}>
            <div className={[classes.solutionsRowImg, ""].join(" ")}>
              <img src={img1} alt="Our Story" />
            </div>
            <div className={[classes.solutionsRowImg1, ""].join(" ")}>
              <img src={palmaImg} alt="Our Story" />
            </div>
            <div className={[classes.solutionsRowLeft, ""].join(" ")}>
              <h2 className={[classes.solutionsRowLeftTitle, classes.opacity].join(" ")}>
                <Trans>
                  {t('about_our_story_title')}
                </Trans>
              </h2>
              <h2 className={[classes.solutionsRowLeftTitle, classes.opacity, classes.mob].join(" ")}>
                <Trans>
                  {t('about_our_story_title')}
                </Trans>
              </h2>
              <p className={[classes.solutionsRowLeftText, "font-17"].join(" ")}>
                {t('about_our_story_text_01')}
              </p>
              <p className={[classes.solutionsRowLeftText, "font-17"].join(" ")}>
                {t('about_our_story_text_02')}
              </p>
              <p className={[classes.solutionsRowLeftText, "font-17"].join(" ")}>
                {t('about_our_story_text_03')}
              </p>
            </div>
          </div>
        </div>
        <div className={[classes.mob, ""].join(" ")}>
          <img src={imgMob} alt="Our Story" />
        </div>
      </div>
    </div>
  );
};

export default AboutStory;
