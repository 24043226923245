import React, { useEffect, useRef, useState } from "react";
import classes from './EnergyComm.module.scss';
import { useTranslation } from "react-i18next";
import check from '../../../assets/img/CommoditiesPage/EnergyComm/check.svg';
import circle from '../../../assets/img/CommoditiesPage/EnergyComm/circle.png';
import arrow from '../../../assets/img/CommoditiesPage/EnergyComm/arrow.svg';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const EnergyComm = () => {
    const { t } = useTranslation();
    const [ swiper, setSwiper ] = useState({});
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const cardBodyRef_01 = useRef(null);
    const cardBodyRef_02 = useRef(null);
    const cardBodyRef_03 = useRef(null);
    const cardBodyRef_04 = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            refBody: cardBodyRef_01,
            title: 'comm_energy_card_01_title',
            list: [
                'comm_energy_card_01_li_01',
                'comm_energy_card_01_li_02',
                'comm_energy_card_01_li_03',
                'comm_energy_card_01_li_04',
            ],
        },
        {
            ref: cardRef_02,
            refBody: cardBodyRef_02,
            title: 'comm_energy_card_02_title',
            list: [
                'comm_energy_card_02_li_01',
                'comm_energy_card_02_li_02',
                'comm_energy_card_02_li_03',
                'comm_energy_card_02_li_04',
            ],
        },
        {
            ref: cardRef_03,
            refBody: cardBodyRef_03,
            title: 'comm_energy_card_03_title',
            list: [
                'comm_energy_card_03_li_01',
                'comm_energy_card_03_li_02',
            ],
        },
        {
            ref: cardRef_04,
            refBody: cardBodyRef_04,
            title: 'comm_energy_card_04_title',
            list: [
                'comm_energy_card_04_li_01',
                'comm_energy_card_04_li_02',
            ],
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: cardBodyRef_01.current,
                    end: "+=400px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_02.current, {
                y: 100,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: cardBodyRef_02.current,
                    end: "+=400px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_03.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: cardBodyRef_03.current,
                    end: "+=400px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_04.current, {
                y: 150,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: cardBodyRef_04.current,
                    end: "+=400px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className={classes.energyCommWrap}>
            <img className={classes.circle} src={circle} alt=''/>
            <div className="container mt">
                <div className={classes.energyComm}>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-100'].join(' ')}>
                            {t('comm_energy_title_01')}
                        </h2>
                        <h3 className={[classes.subtitle, 'font-35'].join(' ')}>
                            {t('comm_energy_title_02')}
                        </h3>
                        <p className={[classes.text, 'font-17'].join(' ')}>
                            {t('comm_energy_text')}
                        </p>
                    </div>
                    <div className={classes.cards}>
                        {cards.map((card, index) =>
                            <div ref={card.refBody} key={index} className={classes.cardWrap}>
                                <div ref={card.ref} className={classes.card}>
                                    <h4 className={classes.cardTitle}>
                                        {t(card.title)}
                                    </h4>
                                    <ul>
                                        {card.list.map((li, liIndex) =>
                                            <li key={liIndex}>
                                                <img className={classes.check} src={check} alt=''/>
                                                <span>
                                                    {t(li)}
                                                </span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.swiperWrap}>
                        <div className={classes.pagination}>
                            <img
                                onClick={() => swiper.slidePrev()}
                                src={arrow}
                                alt=''
                            />
                            <img
                                onClick={() => swiper.slideNext()}
                                src={arrow}
                                alt=''
                            />
                        </div>
                        <Swiper
                            modules={[Navigation]}
                            className="commEnergyCommSwiper"
                            spaceBetween={20}
                            slidesPerView={1}
                            onInit={(e) => { setSwiper(e) }}
                        >
                            {cards.map((card, index) =>
                                <SwiperSlide key={index} className="commEnergyCommSwiperSlide">
                                    <div className={classes.card}>
                                        <h4 className={classes.cardTitle}>
                                            {t(card.title)}
                                        </h4>
                                        <ul>
                                            {card.list.map((li, liIndex) =>
                                                <li key={liIndex}>
                                                    <img className={classes.check} src={check} alt=''/>
                                                    <span>
                                                        {t(li)}
                                                    </span>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EnergyComm;
