import React from "react";
import classes from './SolutionsBottom.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/CommoditiesPage/SolutionsBottom/image_01.png';
import image_02 from '../../../assets/img/CommoditiesPage/SolutionsBottom/image_02.png';

const cards = [
    {
        image: image_01,
        title: 'comm_power_trading_solutions_card_title_01',
        text: 'comm_power_trading_solutions_card_text_01',
    },
    {
        image: image_02,
        title: 'comm_power_trading_solutions_card_title_02',
        text: 'comm_power_trading_solutions_card_text_02',
    },
];

const SolutionsBottom = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.solutionsBottom}>
                <h2 className={[classes.title, 'font-100'].join(' ')}>
                    <Trans>
                        {t('comm_power_trading_solutions_title')}
                    </Trans>
                </h2>
                <p className={[classes.text, 'font-17'].join(' ')}>
                    {t('comm_power_trading_solutions_text')}
                </p>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <img className={classes.cardImage} src={card.image} alt=''/>
                            <h4 className={[classes.cardTitle, 'font-17'].join(' ')}>
                                {t(card.title)}
                            </h4>
                            <p className={classes.cardText}>
                                {t(card.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default SolutionsBottom;
