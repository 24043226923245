import React from "react";
import classes from '../NaturalGas/NaturalGas.module.scss';
import { useTranslation } from "react-i18next";

const BaseOil = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <h2 className={[classes.title, 'font-35'].join(' ')}>
                {t('comm_trade_base_oil_title')}
            </h2>
            <div className={classes.container}>
                <div className={classes.col}>
                    <div className={classes.content}>
                        <div className={[classes.text, 'font-17'].join(' ')}>
                            <p>
                                {t('comm_trade_base_oil_text_01')}
                            </p>
                            <p>
                                {t('comm_trade_base_oil_text_02')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={classes.col}>
                    <div className={classes.content}>
                        <div className={[classes.text, 'font-17'].join(' ')}>
                            <p>
                                {t('comm_trade_base_oil_text_03')}
                            </p>
                            <p>
                                {t('comm_trade_base_oil_text_04')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BaseOil;
