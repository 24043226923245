import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/ContactPage/Banner/Banner";
import HomeOurPartners from "../../components/MainComponents/HomeOurPartners/HomeOurPartners";
import Form from "../../components/ContactPage/Form/Form";

const ContactPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Contact Us</title>
        <meta
          name="title"
          content="Contact Digital Ventures | Get in Touch with Our Support Team"
        />
        <meta
          property="og:title"
          content="Contact Digital Ventures | Get in Touch with Our Support Team"
        />
        <meta
          property="twitter:title"
          content="Contact Digital Ventures | Get in Touch with Our Support Team"
        />

        <meta
          name="description"
          content="Contact Digital Ventures for any inquiries, assistance, or feedback. Our dedicated support team is here to provide you with prompt and helpful responses. Reach out to us today."
        />
        <meta
          property="og:description"
          content="Contact Digital Ventures for any inquiries, assistance, or feedback. Our dedicated support team is here to provide you with prompt and helpful responses. Reach out to us today."
        />
        <meta
          property="twitter:description"
          content="Contact Digital Ventures for any inquiries, assistance, or feedback. Our dedicated support team is here to provide you with prompt and helpful responses. Reach out to us today."
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden page-bg">
        <Header />
        <Banner />
        <Form />
        <HomeOurPartners />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default ContactPage;
