import React, { useState } from "react";
import classes from "./HomeFaq.module.scss";
import { useTranslation } from "react-i18next";
import arrow from "../../../assets/img/home/7/arrow.svg";

const tabs = [
  {
    title: "home_faq_q_01",
    text: "home_faq_a_01",
  },
  {
    title: "home_faq_q_02",
    text: "home_faq_a_02",
  },
  {
    title: "home_faq_q_03",
    text: "home_faq_a_03",
  },
  // {
  //   title: "home_faq_q_04",
  //   text: "home_faq_a_04",
  // },
  {
    title: "home_faq_q_05",
    text: "home_faq_a_05",
  },
];

const HomeFaq = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);
  const setTab = (value) => {
    if (currentTab === value) {
      setCurrentTab(-1);
      return;
    }
    setCurrentTab(value);
  };
  return (
    <div className={[classes.wrap, ""].join(" ")}>
      <section className="mt container">
        <div className={classes.container}>
          <div className={classes.header}>
            <h2 className={[classes.titleMy, ""].join(" ")}>
              {t('home_faq_title')}
            </h2>
          </div>
          <div className={classes.tabs}>
            {tabs.map((tab, index) => (
              <div
                className={`${classes.tab} ${
                  index === currentTab && classes.tabActive
                } no-select`}
                onClick={() => setTab(index)}
                key={index}
              >
                <div className={classes.status}>
                  <img src={arrow} alt="" />
                </div>
                <div className={[classes.tabTitle, "font-17"].join(" ")}>
                  {t(tab.title)}
                </div>
                <span></span>
                <div className={classes.tabText}>{t(tab.text)}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeFaq;
