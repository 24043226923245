import React from "react";
import classes from "./HomeNotebook.module.scss";
import img1 from "../../../assets/img/home/5/1.png";
import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);
const HomeNotebook = () => {
  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const mainRef = useRef(null);

  const slideRef1 = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(slideRef1.current, {
        y: 0,
        delay: 0.3,
        autoAlpha: 1,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "center center",
          scrub: 1,
        },
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={[classes.notebook, "mt"].join(" ")} ref={mainRef}>
      <div className={[classes.notebookBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.notebookRow, ""].join(" ")}>
            <div
              className={[classes.notebookRowLeft, ""].join(" ")}
              ref={slideRef1}
            >
              <img
                src={img1}
                alt="TRADE Platform: Empowering Independent Commodity Trading"
              />
            </div>
            <div className={[classes.notebookRowRight, ""].join(" ")}>
              <p
                className={[classes.notebookRowRightText, "font-17"].join(" ")}
              >
                {t('home_trade_platform_text_04')}
              </p>
              <p
                className={[classes.notebookRowRightText, "font-17"].join(" ")}
              >
                {t('home_trade_platform_text_05')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeNotebook;
