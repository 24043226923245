import React from "react";
import classes from "./AccountsWhyChoose.module.scss";
import img1 from "../../../assets/img/trading/3/1.png";
import imgI1 from "../../../assets/img/trading/3/i1.png";
import imgI2 from "../../../assets/img/trading/3/i2.png";
import imgI3 from "../../../assets/img/trading/3/i3.png";
import imgI4 from "../../../assets/img/trading/3/i4.png";

import imgMob from "../../../assets/img/trading/3/mob.png";
import { Trans, useTranslation } from "react-i18next";

const AccountsWhyChoose = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.whyChoose, "mt mb"].join(" ")}>
      <div className={[classes.whyChooseBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.whyChooseRow, ""].join(" ")}>
            <div className={[classes.whyChooseRowLeft, ""].join(" ")}>
              <h2
                className={[classes.whyChooseRowLeftTitle, "font-35"].join(" ")}
              >
                {t('accounts_why_choose_title')}
              </h2>
              <ul className={[classes.whyChooseRowLeftList, ""].join(" ")}>
                <li
                  className={[classes.whyChooseRowLeftListItem, "font-17"].join(
                    " "
                  )}
                >
                  <Trans>
                    {t('accounts_why_choose_text_01')}
                  </Trans>
                </li>
                <li
                  className={[classes.whyChooseRowLeftListItem, "font-17"].join(
                    " "
                  )}
                >
                  <Trans>
                    {t('accounts_why_choose_text_02')}
                  </Trans>
                </li>
                <li
                  className={[classes.whyChooseRowLeftListItem, "font-17"].join(
                    " "
                  )}
                >
                  <Trans>
                    {t('accounts_why_choose_text_03')}
                  </Trans>
                </li>
                <li
                  className={[classes.whyChooseRowLeftListItem, "font-17"].join(
                    " "
                  )}
                >
                  <Trans>
                    {t('accounts_why_choose_text_04')}
                  </Trans>
                </li>
                <li
                  className={[classes.whyChooseRowLeftListItem, "font-17"].join(
                    " "
                  )}
                >
                  <Trans>
                    {t('accounts_why_choose_text_05')}
                  </Trans>
                </li>
              </ul>
            </div>
            <div className={[classes.whyChooseRowRight, ""].join(" ")}>
              <div className={[classes.whyChooseRowRightImg, ""].join(" ")}>
                <img src={img1} alt="Why choose a Digital Ventures Trading Account?" />
              </div>
              <div className={[classes.whyChooseRowRightImgI1, ""].join(" ")}>
                <img src={imgI1} alt="Why choose a Digital Ventures Trading Account?" />
              </div>
              <div className={[classes.whyChooseRowRightImgI2, ""].join(" ")}>
                <img src={imgI2} alt="Why choose a Digital Ventures Trading Account?" />
              </div>
              <div className={[classes.whyChooseRowRightImgI3, ""].join(" ")}>
                <img src={imgI3} alt="Why choose a Digital Ventures Trading Account?" />
              </div>
              <div className={[classes.whyChooseRowRightImgI4, ""].join(" ")}>
                <img src={imgI4} alt="Why choose a Digital Ventures Trading Account?" />
              </div>
            </div>
          </div>
          <div className={[classes.whyChooseMob, ""].join(" ")}>
            <img src={imgMob} alt="Why choose a Digital Ventures Trading Account?" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountsWhyChoose;
