import { useRef } from "react";
import classes from "./AboutOurMission.module.scss";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { Trans, useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const AboutOurMission = () => {
  const { t } = useTranslation();
  gsap.config({
    force3D: true,
  });
  const mainRef = useRef(null);
  return (
    <div className={[classes.solutions, ""].join(" ")} ref={mainRef}>
      <div className={[classes.solutionsBody, ""].join(" ")}>
        <div className="container">
              <h2
                className={[classes.solutionsRowLeftTitle, "font-80"].join(" ")}
              >
                <Trans>
                  {t('about_our_mission_title')}
                </Trans>
              </h2>
          <div className={[classes.solutionsRow, ""].join(" ")}>
            <div className={[classes.solutionsRowLeft, ""].join(" ")}>

              <p
                className={[classes.solutionsRowLeftText, "font-17"].join(" ")}
              >
                {t('about_our_mission_text_01')}
              </p>
              <p
                className={[classes.solutionsRowLeftText, "font-17"].join(" ")}
              >
                {t('about_our_mission_text_02')}
              </p>
            </div>
            <div className={[classes.solutionsRowLeft, ""].join(" ")}>
              <p
                className={[classes.solutionsRowLeftText, "font-17"].join(" ")}
              >
                {t('about_our_mission_text_03')}
              </p>
              <p
                className={[
                  classes.solutionsRowLeftText,
                  classes.fw600,
                  "font-17",
                ].join(" ")}
              >
                {t('about_our_mission_text_04')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOurMission;
