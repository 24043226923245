import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import circle from '../../../assets/img/BonusesPage/Banner/circle.png';
import image from '../../../assets/img/ContactPage/banner.png';

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.bannerWrap}>
            <img className={classes.circle} src={circle} alt=''/>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h1 className={[classes.title, 'font-35'].join(' ')}>
                            {t('contact_banner_title')}
                        </h1>
                        <div className={[classes.text, 'font-17'].join(' ')}>
                            <p>
                                {t('contact_banner_text_01')}
                            </p>
                            <p>
                                {t('contact_banner_text_02')}
                            </p>
                            <p>
                                {t('contact_banner_text_03')}
                            </p>
                        </div>
                    </div>
                    <img className={classes.image} src={image} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default Banner;
