import React from "react";
import classes from "./HomeOurPartners.module.scss";
import img1 from "../../../assets/img/home/8/1.png";
import img2 from "../../../assets/img/home/8/2.png";
import img3 from "../../../assets/img/home/8/3.png";
import img4 from "../../../assets/img/home/8/4.png";
import img5 from "../../../assets/img/home/8/5.png";
import img6 from "../../../assets/img/home/8/6.png";
import img7 from "../../../assets/img/home/8/7.png";
import img8 from "../../../assets/img/home/8/8.png";
import img9 from "../../../assets/img/home/8/9.png";
import img10 from "../../../assets/img/home/8/10.png";
import Marquee from "react-double-marquee";
import { useTranslation } from "react-i18next";
const HomeOurPartners = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.ourPatners, "mt"].join(" ")}>
      <div className={[classes.ourPatnersBody, ""].join(" ")}>
        <h3 className={[classes.ourPatnersTitle, "font-35"].join(" ")}>
          {t('home_our_partners')}
        </h3>

        <div
          className={[classes.ourPatnersBodyRow1, ""].join(" ")}
          style={{
            width: "100%",
            whiteSpace: "nowrap",
          }}
        >
          <Marquee delay={1000} className={[classes.wrap, ""].join(" ")}>
            <div className={[classes.ourPatnersBodyRow1Column, ""].join(" ")}>
              <img src={img1} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow1Column, ""].join(" ")}>
              <img src={img2} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow1Column, ""].join(" ")}>
              <img src={img3} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow1Column, ""].join(" ")}>
              <img src={img4} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow1Column, ""].join(" ")}>
              <img src={img5} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow1Column, ""].join(" ")}>
              <img src={img1} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow1Column, ""].join(" ")}>
              <img src={img2} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow1Column, ""].join(" ")}>
              <img src={img3} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow1Column, ""].join(" ")}>
              <img src={img4} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow1Column, ""].join(" ")}>
              <img src={img5} alt="" />
            </div>
          </Marquee>
        </div>
        <div
          className={[classes.ourPatnersBodyRow2, ""].join(" ")}
          style={{
            width: "100%",
            whiteSpace: "nowrap",
          }}
        >
          <Marquee
            delay={1000}
            className={[classes.ourPatnersBodyRow2Wrap, ""].join(" ")}
          >
            <div className={[classes.ourPatnersBodyRow2Column, ""].join(" ")}>
              <img src={img6} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow2Column, ""].join(" ")}>
              <img src={img7} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow2Column, ""].join(" ")}>
              <img src={img8} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow2Column, ""].join(" ")}>
              <img src={img9} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow2Column, ""].join(" ")}>
              <img src={img10} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow2Column, ""].join(" ")}>
              <img src={img6} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow2Column, ""].join(" ")}>
              <img src={img7} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow2Column, ""].join(" ")}>
              <img src={img8} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow2Column, ""].join(" ")}>
              <img src={img9} alt="" />
            </div>
            <div className={[classes.ourPatnersBodyRow2Column, ""].join(" ")}>
              <img src={img10} alt="" />
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default HomeOurPartners;
