import logo from '../assets/img/logo.png';
import img1 from '../assets/img/BonusesPage/Banner/circle.png';
import img2 from '../assets/img/BonusesPage/Banner/cube_01.png';
import img3 from '../assets/img/BonusesPage/Banner/cube_02.png';
import img4 from '../assets/img/BonusesPage/Banner/cube_03.png';
import img5 from '../assets/img/BonusesPage/Banner/man.png';
import img6 from '../assets/img/CommoditiesPage/banner.png';

import img7 from '../assets/img/ContactPage/banner.png';
import img8 from '../assets/img/about/1/1.png';
import img9 from '../assets/img/about/1/2.png';
import img10 from '../assets/img/about/1/m1.png';
import img11 from '../assets/img/about/1/m2.png';
import img12 from '../assets/img/about/1/m3.png';
import img13 from '../assets/img/about/1/m4.png';
import img14 from '../assets/img/about/1/mob.png';

import img15 from '../assets/img/home/1/1.png';
import img16 from '../assets/img/home/1/m1.png';
import img17 from '../assets/img/home/1/m2.png';
import img18 from '../assets/img/home/1/m3.png';
import img19 from '../assets/img/home/1/m4.png';
import img20 from '../assets/img/home/1/mob.png';

import img21 from '../assets/img/trading/1/1.png';
import img22 from '../assets/img/trading/1/mob.png';
import img23 from '../assets/img/trading/1/mobBg.png';

export const images = [
    logo,
    img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23,


];
