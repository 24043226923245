import React from "react";
import classes from "./AccountsWhat.module.scss";
import { useTranslation } from "react-i18next";

const cards = [
  {
    title: 'Basic',
    deposit: '$250',
    desc: 'home_acc_types_desc_01',
    list: [
      'home_acc_types_01_li_01',
      'home_acc_types_01_li_02',
      'home_acc_types_01_li_03',
      'home_acc_types_01_li_04',
      'home_acc_types_01_li_05',
    ]
  },
  {
    title: 'Silver',
    deposit: '$10.000',
    desc: 'home_acc_types_desc_02',
    list: [
      'home_acc_types_02_li_01',
      'home_acc_types_01_li_02',
      'home_acc_types_02_li_03',
      'home_acc_types_01_li_03',
      'home_acc_types_02_li_05',
      'home_acc_types_01_li_04',
    ]
  },
  {
    title: 'Gold',
    deposit: '$70.000',
    desc: 'home_acc_types_desc_03',
    list: [
      'home_acc_types_03_li_01',
      'home_acc_types_01_li_02',
      'home_acc_types_02_li_03',
      'home_acc_types_01_li_03',
      'home_acc_types_02_li_05',
      'home_acc_types_01_li_04',
    ]
  },
  {
    title: 'Platinum',
    deposit: '$200.000',
    desc: 'home_acc_types_desc_04',
    list: [
      'home_acc_types_04_li_01',
      'home_acc_types_04_li_02',
      'home_acc_types_02_li_03',
      'home_acc_types_04_li_04',
      'home_acc_types_02_li_05',
      'home_acc_types_01_li_03',
      'home_acc_types_04_li_07',
      'home_acc_types_01_li_04',
    ]
  },
  {
    title: 'VIP',
    deposit: '$500.000',
    desc: 'home_acc_types_desc_05',
    list: [
      'home_acc_types_05_li_01',
      'home_acc_types_05_li_02',
      'home_acc_types_02_li_03',
      'home_acc_types_05_li_04',
      'home_acc_types_01_li_03',
      'home_acc_types_02_li_05',
      'home_acc_types_04_li_07',
      'home_acc_types_01_li_04',
      'home_acc_types_05_li_09',
    ]
  },
];

const AccountsWhat = () => {
  const { t } = useTranslation();
  return (
    <section className="mt mb container">
      <div className={classes.accountsWhat}>
        <div className={classes.cards}>
          {cards.map((card, index) =>
            <div key={index} className={classes.card}>
              <div className={classes.cardContent}>
                <h4 className={classes.cardTitle}>
                  {t(card.title)}
                </h4>
                <p className={classes.cardDep}>
                  {t('home_acc_types_col_02')}: {card.deposit}
                </p>
                <p className={`${classes.cardDesc} font-17`}>
                  {t(card.desc)}
                </p>
                <ul className="font-17">
                  {card.list.map((li, liIndex) =>
                    <li key={liIndex}>
                      {t(li)}
                    </li>
                  )}
                </ul>
              </div>
              <div className={classes.btnWrap}>
                <a href="https://user.vendigital.one/signup" className={classes.btn}>
                  <span>
                    {t('btn_start_now')}
                  </span>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AccountsWhat;
