import React from "react";
import classes from './ContactManager.module.scss';

const ContactManager = () => {
    return (
        <div className={classes.contactManager}>
            <p>
                Something went wrong.<br/>Contact your manager.
            </p>
        </div>
    );
};

export default ContactManager;
