import React from "react";
import classes from "./HomeTrade.module.scss";
import img1 from "../../../assets/img/home/4/1.png";
import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { Trans, useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);
const HomeTrade = () => {
  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const mainRef = useRef(null);

  const slideRef1 = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(slideRef1.current, {
        y: 0,
        delay: 0.3,
        autoAlpha: 1,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "center center",
          scrub: 1,
        },
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={[classes.homeTrade, "mt"].join(" ")} ref={mainRef}>
      <div className={[classes.homeTradeBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.homeTradeRow, ""].join(" ")}>
            <div className={[classes.homeTradeRowLeft, ""].join(" ")}>
              <h2
                className={[classes.homeTradeRowLeftTitle, "font-35"].join(" ")}
              >
                <Trans>
                  {t('home_trade_platform_title')}
                </Trans>
              </h2>
              <p
                className={[classes.homeTradeRowLeftText, "font-17"].join(" ")}
              >
                {t('home_trade_platform_text_01')}
              </p>
              <p
                className={[classes.homeTradeRowLeftText, "font-17"].join(" ")}
              >
                {t('home_trade_platform_text_02')}
              </p>

              <p
                className={[classes.homeTradeRowLeftText, "font-17"].join(" ")}
              >
                {t('home_trade_platform_text_03')}
              </p>
            </div>
            <div
              className={[classes.homeTradeRowRight, ""].join(" ")}
              ref={slideRef1}
            >
              <img
                src={img1}
                alt="TRADE Platform: Empowering Independent Commodity Trading"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTrade;
