import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import man from '../../../assets/img/BonusesPage/Banner/man.png';
import circle from '../../../assets/img/BonusesPage/Banner/circle.png';
import cube_01 from '../../../assets/img/BonusesPage/Banner/cube_01.png';
import cube_02 from '../../../assets/img/BonusesPage/Banner/cube_02.png';
import cube_03 from '../../../assets/img/BonusesPage/Banner/cube_03.png';
import { useRef } from "react";
import { useEffect } from "react";
import { gsap } from "gsap";
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const cubeRef_01 = useRef(null);
    const cubeRef_02 = useRef(null);
    const cubeRef_03 = useRef(null);
    const manRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.fromTo(manRef.current, {
                scale: 0.9,
            }, {
                scale: 1,
                delay: 0.5,
                duration: 1,
            });
            gsap.fromTo(cubeRef_01.current, {
                scale: 0.8,
                autoAlpha: 0,
                rotate: 50,
                y: -200,
            }, {
                scale: 1,
                autoAlpha: 1,
                y: 0,
                rotate: 0,
                delay: 0.5,
                duration: 1,
            });
            gsap.fromTo(cubeRef_02.current, {
                scale: 0.8,
                autoAlpha: 0,
                rotate: -30,
                y: -120,
            }, {
                scale: 1,
                autoAlpha: 1,
                y: 0,
                rotate: 0,
                delay: 0.5,
                duration: 1,
            });
            gsap.fromTo(cubeRef_03.current, {
                scale: 0.8,
                autoAlpha: 0,
                rotate: 40,
                y: -100,
            }, {
                scale: 1,
                autoAlpha: 1,
                y: 0,
                rotate: 0,
                delay: 0.5,
                duration: 1,
            });
        }
    }, []);
    return (
        <section className={classes.bannerWrap}>
            <img className={classes.circle} src={circle} alt=''/>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-80'].join(' ')}>
                            <p>
                                <span>
                                    {/* {t('bonuses_banner_title_01')} */}
                                    {t('bonuses_banner_title_02')}
                                </span>&nbsp;
                            </p>
                            {/* <p>
                                {t('bonuses_banner_title_02')}
                            </p> */}
                        </h2>
                        <div className={[classes.subtitle, 'font-35'].join(' ')}>
                            {t('bonuses_banner_subtitle')}
                        </div>
                        <div className={[classes.text, 'font-17'].join(' ')}>
                            <p>
                                {t('bonuses_banner_text_01')}
                            </p>
                            <p>
                                {t('bonuses_banner_text_02')}
                            </p>
                            <p>
                                {t('bonuses_banner_text_03')}
                            </p>
                        </div>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn_start_trading')}
                            </Button>
                        </div>
                    </div>
                    <div className={classes.images}>
                        <img ref={manRef} className={classes.man} src={man} alt=''/>
                        <div ref={cubeRef_01} className={[classes.cube, classes.cube_01].join(' ')}>
                            <img src={cube_01} alt=''/>
                        </div>
                        <div ref={cubeRef_02} className={[classes.cube, classes.cube_02].join(' ')}>
                            <img src={cube_02} alt=''/>
                        </div>
                        <div ref={cubeRef_03} className={[classes.cube, classes.cube_03].join(' ')}>
                            <img src={cube_03} alt=''/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
