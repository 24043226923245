import HomePage from '../pages/HomePage/HomePage';
import AccoutsPage from '../pages/AccountsPage/AccountsPage';
import AboutPage from '../pages/AboutPage/AboutPage';
import CommoditiesPage from '../pages/CommoditiesPage/CommoditiesPage';
import BonusesPage from '../pages/BonusesPage/BonusesPage';
import ContactPage from '../pages/ContactPage/ContactPage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/commodities', element: CommoditiesPage },
    { path: '/accounts', element: AccoutsPage },
    { path: '/bonuses', element: BonusesPage },
    { path: '/contact', element: ContactPage },
    { path: '/about', element: AboutPage },
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/commodities', element: CommoditiesPage },
    { path: '/:lang/accounts', element: AccoutsPage },
    { path: '/:lang/bonuses', element: BonusesPage },
    { path: '/:lang/contact', element: ContactPage },
    { path: '/:lang/about', element: AboutPage },
];

export const headerRoutes = [
    { id: 1, path: 'commodities', text: 'header_commodities' },
    { id: 2, path: 'accounts', text: 'header_trading_acc' },
    { id: 3, path: 'bonuses', text: 'header_bonuses' },
    { id: 4, path: 'contact', text: 'header_contact' },
    { id: 5, path: 'about', text: 'header_about' },
];
