import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import AboutBanner from "../../components/AboutComponents/AboutBanner/AboutBanner";
import AboutOurMission from "../../components/AboutComponents/AboutOurMission/AboutOurMission";
import AboutStory from "../../components/AboutComponents/AboutStory/AboutStory";
import AboutOurValues from "../../components/AboutComponents/AboutOurValues/AboutOurValues";
import AboutSpeakToUs from "../../components/AboutComponents/AboutSpeakToUs/AboutSpeakToUs";
import Slider from "../../components/MainComponents/Slider/Slider";
import HomeOurPartners from "../../components/MainComponents/HomeOurPartners/HomeOurPartners";
const AboutPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>About Us</title>
        <meta
          name="title"
          content="About Digital Ventures | Your Trusted Independent Commodity Trading Platform"
        />
        <meta
          property="og:title"
          content="About Digital Ventures | Your Trusted Independent Commodity Trading Platform"
        />
        <meta
          property="twitter:title"
          content="About Digital Ventures | Your Trusted Independent Commodity Trading Platform"
        />

        <meta
          name="description"
          content="Learn about Digital Ventures, the leading independent commodity trading platform. Discover our mission, advanced trading features, and commitment to empowering traders worldwide."
        />
        <meta
          property="og:description"
          content="Learn about Digital Ventures, the leading independent commodity trading platform. Discover our mission, advanced trading features, and commitment to empowering traders worldwide."
        />
        <meta
          property="twitter:description"
          content="Learn about Digital Ventures, the leading independent commodity trading platform. Discover our mission, advanced trading features, and commitment to empowering traders worldwide."
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden page-bg">
        <Header />
        <AboutBanner />
        <AboutOurMission />
        <AboutStory />
        <AboutOurValues />
        <AboutSpeakToUs />
        <Slider />
        <HomeOurPartners />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AboutPage;
